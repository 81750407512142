@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;;
}

@mixin mobile {
  @media only screen and (max-width: 768px) {
    @content
  }
}