@use 'variables' as v;
@use 'mixins' as m;

.contact {
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1.5em !important;
  .title {
    margin: 1.5em 0 !important;
  }
  @include m.mobile {
    margin: 0  !important;
  }
  .contact-form {
    @include m.mobile {
      margin: 0  !important;
    }
  }
}
