
// colors
$black: #0f0f0f;
$gray: #939598;
$white: #FFFFFF;
$oatmeal: #EFEAE1;
$offwhite: #f6f5f2;
$sage: #e2ebdd;
//fonts
$brandon: 'Brandon Grotesque', sans-serif;
$effra: 'Effra', sans-serif;
$neue: "Neue Montreal Light", sans-serif;
$iskry: 'Iskry Regular', sans-serif;