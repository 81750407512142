@use 'variables' as v;

.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  .subtitle {
    font-family: v.$iskry;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: 2px;
  }
  .form-fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  label, button {
    font-family: v.$neue;
    font-size: 16px;
    margin-top: 10px;
    letter-spacing: 2px;
  }
  input, select, textarea {
    margin: 10px 0;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    letter-spacing: 2px;
  }
  textarea {
    height: 120px;
  }
  input[type='submit'] {
    cursor: pointer;
  }
  select {
    letter-spacing: 2px;
    font-size: 12px;
    color: v.$gray;
  }
  input::placeholder {
    letter-spacing: 2px;
    color: v.$gray;
    font-size: 12px;
  }
}
