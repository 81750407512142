@use 'variables' as v;
@use 'mixins' as m;

.about {
  .title {
    text-align: center;
    margin: 1.5em 0;
  }
  .about-text {
    font-family: v.$neue;
    font-size:16px;
    letter-spacing: 2px;    
    .image {
      float: left;
      margin-right: 2em;
      @include m.mobile {
        float: unset;
        margin: 1.5em auto;
      }
    }
    p {
      line-height: 2em;
    }
    h1 {
      font-family: v.$iskry;
    }
    .video {
      height: 55vh;
      @include m.mobile {
        height: 30vh;
      }
      margin-bottom: 5em;
    }
  }
  .affiliations {
    padding: 1em 0 !important;
    @media only screen and (min-width: 992px) {
      .ui.grid>.row>[class*="two wide computer"].column {
        width: 16.5%!important;
      } 
    }
    .affiliation-logos {
      padding: 2em 0 !important;
      @include m.mobile {
        margin-bottom: 2em;
        .column {
          margin: 2em 0 !important;
        }
      }
    }
    h1 {
      font-family: v.$iskry;
      letter-spacing: 2px;
    }
    li {
      font-size: 16px;
      letter-spacing: 2px;
      font-family: v.$neue;
      line-height: 2em;
      @include m.mobile {
        margin: 0 1em;
      }
    }
    .logo {
      @include m.mobile {
        margin-left: unset !important;
        margin-right: unset !important;
      }
    }
  }
  .bryant-content {
    margin-bottom: 4em;
  }
  .about-text, .affiliations, .affiliation-column {

    .fade-in-section:nth-child(1) {
      transition-delay: 150ms;
    }
    .fade-in-section:nth-child(2) {
      transition-delay: 300ms;
    }
    .fade-in-section:nth-child(3) {
      transition-delay: 450ms;
    }
    .fade-in-section:nth-child(4) {
      transition-delay: 600ms;
    }
    .fade-in-section:nth-child(5) {
      transition-delay: 750ms;
    }
    .fade-in-section:nth-child(6) {
      transition-delay: 900ms;
    }
    .fade-in-section:nth-child(7) {
      transition-delay: 1050ms;
    }
    .fade-in-section:nth-child(8) {
      transition-delay: 1200ms;
    }
    .fade-in-section:nth-child(9) {
      transition-delay: 1350ms;
    }
  }
}