@use 'variables' as v;
@use 'mixins' as m;

body {
  margin: 0;
  padding: 0;
  font-family: v.$neue;
  background-color: #fcfcfc;
}
.contact-info {
  text-align: right;
  width: 90vw;
  font-size: 16px;
  padding-top: 2em;
  margin-bottom: -2em;
  position: absolute;
  .mail {
    margin-left: 1em;
  }
  @include m.mobile {
    visibility: hidden;
  }
  span {
    -webkit-text-stroke: 1px #eee;
    color: #eee;
    letter-spacing: 2px;
  }
  i {
    color: white;
  }
}
.container {
  max-width: 90vw;
  margin: 0 auto;
}

.navbar {
  position: relative;
}
.header- {
  @include m.mobile {
    background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/d1icm4lksjozga6y5fye') no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.header-about {
  background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/xmctef45wz3wwv1bxvwb') no-repeat center center fixed;
  height: 60vh;
  background-position-y: -35vh;
  background-size: cover;
  @include m.mobile {
    background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/yz6pipqxy2fjdirk9cas') no-repeat;
    background-size: cover;
  }
}
.header-blog {
  // background: url('../assets/interior.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
}
.header-market-updates {
  // background: url('../assets/interior.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
}
.header-buyers {
  // background: url('../assets/interior.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
}
.header-sellers {
  // background: url('../assets/interior.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
}
.header-contact {
  background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/rmbwaqpk3oqsvxjybjsc') no-repeat center center fixed;
  background-size: cover;
  height: 60vh;
  background-position-y: -35vh;
  @include m.mobile {
    background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/o8gznkodymkwuopj0rma') no-repeat;
    background-size: cover;
  }
}
.header-testimonials {
  background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/a2du5a8knn9k8uqo8jom') no-repeat center center fixed;
  background-size: cover;
  height: 60vh;
  background-position-y: -22vh;
  @include m.mobile {
    background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/lwy1vz3voup9livrfs7q') no-repeat;
    background-size: cover;
  }
}

.header-privacypolicy {
  background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/ooouydebfkjipni36ycd') no-repeat center center fixed;
  background-size: cover;
  height: 60vh;
  background-position-y: -48vh;
  @include m.mobile {
    background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/j3dcpizrjtuqs7jwxjdt') no-repeat;
    background-size: cover;
  }
}
video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;

}
.logo {
  margin-top: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-38%);
}

.logo-image {
  height:155px;
}

.menu-icon {
  display: none;
}

.nav-elements {
  margin: 0 auto;
}

.nav-elements ul {

  list-style-type: none;
  text-align: right;
  margin: 0;
  padding: 6em 0;
}

.nav-elements ul li {
  float: none;
  display: inline-block;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  text-decoration: none;
  font-family: v.$neue;
  color: white;
  -webkit-text-stroke: 1px white;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 2px;
}

.header-about {
  .nav-elements ul a {
    color: v.$black;
    -webkit-text-stroke: 1px v.$black;
    @include m.mobile {
      color: white;
      -webkit-text-stroke: 1px white;
    }
  }
  .contact-info {
    span {
      -webkit-text-stroke: 1px v.$black;
      color: v.$black;
    }
    i {
      color: v.$black;
    }
  }
}
.nav-elements ul li:nth-child(1),
.nav-elements ul li:nth-child(2),
.nav-elements ul li:nth-child(3) {
  float: left;
}

.nav-elements ul a.active {
  position: relative;
}

// .nav-elements ul a.active::after {
//   position: absolute;
//   bottom: -4px;
//   left: 0;
//   width: 100%;
//   height: 2px;
// }

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .container {
    max-width: 95vw;;
  }
}

@media (max-width: 1224px) {
  .logo-image {
    height: 100px;
  }
}
@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    float: right;
    z-index: 2;
    position: relative;
    margin-top: 10px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    background-color: v.$black;
    opacity: 50%;
    width: 0px;
    height: calc(100vh);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 1
  }

  .nav-elements.active {
    width: 100vw;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: 25px !important;
    margin-top: 50px;
  }
  .nav-elements ul a:hover {
    color: v.$white;
  }

}
.tagline {
  font-size: 50px;
  font-family: v.$iskry !important;
  color: v.$white;
  position: relative;
  font-size: 50px;
  top: 25%;
  text-align: center;
  @include m.mobile {
    font-size: 30px;
    top: 40%;
    line-height: 1em;
    margin-left: 2em;
  }
}