@use 'variables' as v;
@use 'mixins' as m;

.home {
  .cta {
    width: 100vw;
    height: 75vh;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  // background-position-y: -43vh;
    .background {
      width: 100vw;
      height: 100%;
      background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/wr8plprsi0qxxs3lhmxr') no-repeat center center fixed;
      background-size: cover;
      filter: contrast(.7);
      @include m.mobile {
        background: url('https://res.cloudinary.com/dlaituqwq/image/upload/f_auto,q_auto/eq7tqyrzgw3t4iymip9t') no-repeat;
        background-size: cover;
      }
    }
    .contact-button {
      font-family: v.$brandon;
    }
    .subtext {
      font-size: 18px;
      letter-spacing: 2px;
      -webkit-text-stroke: 1px v.$black;

    }
    .text-box {
      background-color: white;
      padding:10px;
    }
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction:column;
      background-color: rgba(250, 249, 246, .4);
      padding: 3em;
      position: absolute;
      @include m.mobile {
        width: 80%;
      }
    }
  }
  .about {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: v.$offwhite;
    padding: 2em 0;
    margin: 0 !important;
    @include m.mobile {
      flex-direction: column !important;
    }
    .about-image {
      padding: 60px;
      @include m.mobile {
        padding: 2em 0;
      }
    }
    .about-text {
      font-family: v.$neue;
      font-size: 18px;
      line-height: 40px;
      margin-bottom: 20px;
      letter-spacing: 2px;
      @include m.mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .about-link {
      font-size: 18px;
      line-height: 40px;
      color: black;
      @include m.mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .stats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20vh;
    line-height: 2em;
    background-color: #EFEAE1;
    @include m.mobile {
      flex-direction: column;
      height: fit-content;
    }
    .stat {
      font-size: 28px;
      text-align: center;
      margin: 1em 0;
      padding: 0 6em;
      @include m.mobile {
        font-size: 24px;
      }
      p, span {
        font-family: v.$iskry;
      }
    }
  }
  .areas {
    height: fit-content;
    background-color: v.$sage;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em 0;
    .arches {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 4em;
      @include m.mobile {
        flex-wrap: wrap;
        margin-top: unset;
      }
    }
    .title {
      font-size: 38px;
      font-family: v.$iskry;
      margin-bottom: 30px;
      @include m.mobile {
        font-size: 30px;
      }
    }
    .icon {
      margin: 0 4em;
      @include m.mobile {
        padding: 2em 0;
      }
    }
    img {
      @include m.mobile {
        width: 80px !important;
      }
    }
  }
  .fade-in-section:nth-child(1) {
    transition-delay: 150ms;
  }
  .fade-in-section:nth-child(2) {
    transition-delay: 300ms;
  }
  .fade-in-section:nth-child(3) {
    transition-delay: 450ms;
  }
  .fade-in-section:nth-child(4) {
    transition-delay: 600ms;
  }
  .testimonials {
    height: fit-content;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include m.mobile {
      height: fit-content;
    }
    b {
      -webkit-text-stroke: .75px v.$gray;
    }
    .title {
      font-size: 38px;
      font-family: v.$iskry;
      margin-bottom: 30px;
      @include m.mobile {
        font-size: 30px;
        margin: 0;
      }
    }
    .carousel {
      width: 70vw;
      @include m.mobile {
        width: 100vw;
      }
      li {
        margin: auto;
      }
      .arrow i {
        position: absolute;
        z-index: 2;
        top: calc(50% - 15px);
        cursor: pointer;
        opacity: 50%;
        font-size:1.5em;
      }
      .right {
        right: 0px;
      }
      .left {
        left: 0px;
      }
    
    }
    .carousel-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .carousel-item-text {
      font-family: v.$neue;
      line-height: 40px;
      font-size: 18px;
      letter-spacing: 2px;
      padding: 0 3em;
      @include m.mobile {
        line-height: 30px;
        font-size: 16px;
        padding: 0 3em;
      }
    }
    .testimonials-link {
      color: v.$black;
      font-family: v.$neue;
      letter-spacing: 2px;
      font-size: 18px;
    }
  }
}

.test {
  height: 80vh;
  background-color: v.$oatmeal;
  .title {
    
  }
}